/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Cookies.css";

function Cookies({
  showPolicy,
  handleClosePolicy,
  handlePolicyClick,
  showCustomize,
  handleCustomize,
  setShowCustomize,
}) {
  const [visible, setVisible] = useState(true);
  const [iconVisible, setIconVisible] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Sempre selezionato
    experience: false,
    measurement: false,
    marketing: false,
  });

  // Stati per la visualizzazione dei dettagli dei cookie
  const [showNecessaryDetails, setShowNecessaryDetails] = useState(false);
  const [showExperienceDetails, setShowExperienceDetails] = useState(false);
  const [showMeasurementDetails, setShowMeasurementDetails] = useState(false);
  const [showMarketingDetails, setShowMarketingDetails] = useState(false);

  useEffect(() => {
    const savedPreferences = JSON.parse(
      localStorage.getItem("cookiePreferences")
    );

    if (savedPreferences) {
      setCookiePreferences(savedPreferences);
      setVisible(false);
      setIconVisible(true);
    }
  }, []);

  // Salva le preferenze nel localStorage
  const savePreferences = (preferences) => {
    localStorage.setItem("cookiePreferences", JSON.stringify(preferences));
  };

  // Chiude il banner e mostra l'icona di riapertura
  const handleClose = () => {
    setVisible(false);
    setIconVisible(true);
  };

  // Mostra il banner quando si clicca sull'icona
  const handleIconClick = () => {
    setVisible(true);
    setIconVisible(false);
  };

  // Accetta tutti i cookie
  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      experience: false,
      measurement: false,
      marketing: false,
    };
    setCookiePreferences(allAccepted);
    savePreferences(allAccepted);
    handleClose();
  };

  // Rifiuta tutti i cookie (tranne i necessari)
  const handleRejectAll = () => {
    const onlyNecessary = {
      necessary: true,
      experience: false,
      measurement: false,
      marketing: false,
    };
    setCookiePreferences(onlyNecessary);
    savePreferences(onlyNecessary);
    handleClose();
  };

  // Salva le preferenze scelte dall'utente e chiude il banner
  const handleSaveSettings = () => {
    savePreferences(cookiePreferences);
    handleClose();
    setShowCustomize(false);
  };

  return (
    <>
      {visible && !showCustomize && (
        <div className="cookie-banner">
          <button className="close-button" onClick={handleClose}>
            X
          </button>
          <p>Informativa sintetica/breve.</p>
          <p>
            <strong>Questo sito web utilizza i cookie</strong>
          </p>
          <p>
            In questo sito web sono preselezionati solo cookies
            necessari/tecnici necessari alla navigazione. Per conoscere nel
            dettaglio quali cookie utilizziamo leggi la nostra
            <a href="#" onClick={handlePolicyClick}>
              {" "}
              informativa cookies (estesa)
            </a>
            .
          </p>
          <p>
            Cliccando sulla "X" in alto a destra o sul pulsante "Rifiuta tutti"
            saranno mantenuti attivi solo i cookies necessari, cioè quelli
            tecnici che consentono la navigazione nel sito.
          </p>
          <p>
            Cliccando su "Accetta tutti" si autorizza l'uso di tutti i cookies.
            Cliccando su "Personalizza" si può scegliere in modo libero e
            consapevole quali cookies attivare o lasciare disattivati.
          </p>
          <p>
            Una volta chiuso il banner è presente un'icona che darà la
            possibilità di modificare le scelte sui cookie effettuate
            precedentemente.
          </p>
          <div className="cookie-buttons">
            <button className="accept-button" onClick={handleAcceptAll}>
              Accetta Tutti
            </button>
            <button className="reject-button" onClick={handleRejectAll}>
              Rifiuta Tutti
            </button>
            <button className="customize-button" onClick={handleCustomize}>
              Personalizza
            </button>
          </div>
        </div>
      )}

      {/* Modal per la Policy Estesa */}
      {showPolicy && (
        <div className="policy-modal">
          <div className="policy-modal-content">
            <div className="container-policy-x">
              <h2>COOKIE POLICY</h2>
              <button
                className="close-cookiePolicy-button"
                onClick={handleClosePolicy}
              >
                X
              </button>
            </div>
            <h3>Che cosa sono i cookie?</h3>
            <p>
              I cookie sono piccoli file di dati memorizzati nel computer,
              tablet o smartphone dell’utente. Non si tratta di programmi
              contenenti malware dannoso o virus.
            </p>
            <h3>Uso dei cookie da parte del sito web</h3>
            <p>
              Alcuni cookie svolgono funzioni essenziali per il sito. I cookie
              ci aiutano anche a capire perché l’utente visita il nostro sito,
              consentendoci di ottimizzarlo e strutturarlo in modo mirato alle
              esigenze e agli interessi specifici dell’utente. I cookie
              ricordano elementi come gli articoli aggiunti al carrello, se
              l’utente aveva già visitato il sito in passato, se aveva
              effettuato l’accesso e la lingua e la valuta specifiche nelle
              quali preferisce visualizzare il contenuto del sito. Utilizziamo i
              cookie anche per orientare in modo mirato all’utente la nostra
              pubblicità su altri siti web. In generale, i cookie rientrano in
              quei servizi che ci consentono di presentare contenuti che siano
              per il più possibile interessanti per il visitatore.
            </p>
            <br />
            <p>
              È possibile vedere i servizi specifici che memorizzano i cookie e
              perché li richiedono, raggruppati in categorie diverse:
            </p>
            <br />
            <p>Preferenze, Statistiche, Marketing</p>
            <h3>Per quanto tempo vengono conservati i cookie?</h3>
            <p>
              La durata di conservazione di ciascun cookie dipende dal tipo e
              dallo scopo specifico per cui viene utilizzato. Quando la durata
              di un cookie scade, viene eliminato automaticamente.
            </p>
            <ul>
              <li>
                <strong>Cookie necessari:</strong> Questi cookie sono essenziali
                per il funzionamento del sito e vengono eliminati al termine
                della sessione di navigazione.
              </li>
              <li>
                <strong>Cookie di preferenza:</strong> Utilizzati per ricordare
                le preferenze dell’utente, come lingua e impostazioni del sito,
                con una durata di conservazione di 1 anno.
              </li>
              <li>
                <strong>Cookie statistici:</strong> Raccolgono informazioni
                anonime per analizzare le visite e le prestazioni del sito, con
                una durata di 2 anni.
              </li>
              <li>
                <strong>Cookie di marketing:</strong> Utilizzati per fornire
                contenuti pubblicitari personalizzati, con una durata variabile
                fino a 2 anni.
              </li>
              <li>
                <strong>Cookie di terze parti:</strong> La durata di questi
                cookie è determinata dal provider di terze parti (es. Google,
                Facebook) come indicato nella rispettiva informativa.
              </li>
            </ul>
            <h3>Come rifiutare o eliminare i cookie</h3>
            <p>
              Tutti i cookie e/o i cookie di terzi possono essere rifiutati
              interamente in qualsiasi momento modificando le impostazioni nel
              browser sul computer, tablet or smartphone. L’ubicazione di queste
              impostazioni dipende dal browser in uso. Occorre sapere, tuttavia,
              che rifiutando tutti i cookie e/o quelli di terzi, vi saranno
              funzioni e servizi del sito web che saranno inutilizzabili (perché
              dipendono dai cookie).
            </p>
            <h3>Come è possibile eliminare i cookie?</h3>
            <p>
              È facile eliminare i cookie precedentemente accettati. La
              procedura di eliminazione dipende dal browser (Chrome, Firefox,
              Safari e così via) e dal dispositivo in uso (smartphone, tablet,
              PC, Mac). Generalmente si trova tra le impostazioni - Sicurezza e
              Privacy - ma l’ubicazione può variare tra i diversi browser.
              Precisare quale dispositivo/browser si sta utilizzando (fare clic
              sul link specifico):
            </p>
            <ul>
              <li>
                <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
                  Internet Explorer
                </a>
              </li>
              <li>
                <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">
                  Microsoft Edge
                </a>
              </li>
              <li>
                <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">
                  Mozilla Firefox
                </a>
              </li>
              <li>
                <a href="https://support.google.com/chrome/answer/95647?hl=en">
                  Google Chrome
                </a>
              </li>
              <li>
                <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                  Opera
                </a>
              </li>
              <li>
                <a href="https://support.apple.com/en-us/105082">Safari</a>
              </li>
              <li>
                <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
                  Flash cookies
                </a>
              </li>
              <li>
                <a href="https://support.apple.com/en-us/105082">Apple</a>
              </li>
              <li>
                <a href="https://discover.hubpages.com/technology/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device">
                  Android
                </a>
              </li>
            </ul>
            <h3>Modifica del consenso</h3>
            <p>
              Per modificare il consenso fornito è sufficiente eliminare i
              cookie dal browser o modificare la scelta originaria facendo clic
              sul link seguente:{" "}
              <button
                className="btn-modificaConsenso"
                onClick={handleCustomize}
              >
                Per modificare il consenso, fare clic qui.
              </button>{" "}
              <br /> Si ricorda che: se si utilizzano diversi browser, i cookie
              devono essere eliminati da ciascuno di essi.
            </p>
            <br />
            <p>
              Si invita a consultare la{" "}
              <Link to="/privacy-policy" onClick={handleClosePolicy}>
                Privacy Policy
              </Link>{" "}
              per ulteriori informazioni sui trattamenti di dati personali che
              vengono svolti in conseguenza della navigazione nel presente sito
              web.
            </p>
          </div>
        </div>
      )}

      {showCustomize && (
        <div className="cookie-settings">
          <div>
            <h3>Personalizza i tuoi cookie</h3>
            <button
              className="close-button"
              onClick={() => setShowCustomize(false)}
            >
              X
            </button>
          </div>
          <hr />

          {/* Sezione per i cookie necessari */}
          <div className="cookie-option">
            <div className="container-span-btn-label">
              <div>
                <span className="cookie-option-label">Necessari</span>
                <button
                  className="details-toggle-button"
                  onClick={() => setShowNecessaryDetails((prev) => !prev)}
                >
                  {showNecessaryDetails ? "▲" : "▼"}
                </button>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={cookiePreferences.necessary}
                  disabled
                />
                <span className="slider round"></span>
              </label>
            </div>
            {showNecessaryDetails && (
              <p className="cookie-details">
                I cookie necessari aiutano a contribuire a rendere fruibile un
                sito web abilitando le funzioni di base come la navigazione
                della pagina e l'accesso alle aree protette del sito. Il sito
                web non può funzionare correttamente senza questi cookie.
              </p>
            )}
          </div>

          {/* Sezione per i cookie di preferenza */}
          <div className="cookie-option">
            <div className="container-span-btn-label">
              <div>
                <span className="cookie-option-label">Preferenze</span>
                <button
                  className="details-toggle-button"
                  onClick={() => setShowExperienceDetails((prev) => !prev)}
                >
                  {showExperienceDetails ? "▲" : "▼"}
                </button>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={cookiePreferences.experience}
                  onChange={(e) =>
                    setCookiePreferences((prev) => ({
                      ...prev,
                      experience: e.target.checked,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
            {showExperienceDetails && (
              <p className="cookie-details">
                I cookie per le preferenze consentono a un sito web di ricordare
                le informazioni che influenzano il modo in cui il sito si
                comporta o si presenta, come la lingua preferita o la regione.
              </p>
            )}
          </div>

          {/* Sezione per le statistiche dei cookie */}
          <div className="cookie-option">
            <div className="container-span-btn-label">
              <div>
                <span className="cookie-option-label">Statistiche</span>
                <button
                  className="details-toggle-button"
                  onClick={() => setShowMeasurementDetails((prev) => !prev)}
                >
                  {showMeasurementDetails ? "▲" : "▼"}
                </button>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={cookiePreferences.measurement}
                  onChange={(e) =>
                    setCookiePreferences((prev) => ({
                      ...prev,
                      measurement: e.target.checked,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
            {showMeasurementDetails && (
              <p className="cookie-details">
                I cookie statistici aiutano i proprietari del sito web a capire
                come i visitatori interagiscono con i siti raccogliendo e
                trasmettendo informazioni in forma anonima.
              </p>
            )}
          </div>

          {/* Sezione per i cookie di marketing */}
          <div className="cookie-option">
            <div className="container-span-btn-label">
              <div>
                <span className="cookie-option-label">Marketing</span>
                <button
                  className="details-toggle-button"
                  onClick={() => setShowMarketingDetails((prev) => !prev)}
                >
                  {showMarketingDetails ? "▲" : "▼"}
                </button>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={cookiePreferences.marketing}
                  onChange={(e) =>
                    setCookiePreferences((prev) => ({
                      ...prev,
                      marketing: e.target.checked,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
            {showMarketingDetails && (
              <p className="cookie-details">
                I cookie per il marketing vengono utilizzati per monitorare i
                visitatori nei siti web. L'intento è quello di visualizzare
                annunci pertinenti e coinvolgenti per il singolo utente e quindi
                quelli di maggior valore per gli editori e gli inserzionisti
                terzi.
              </p>
            )}
          </div>

          <button className="save-button" onClick={handleSaveSettings}>
            Salva impostazioni
          </button>
        </div>
      )}

      {iconVisible && (
        <button className="reopen-icon" onClick={handleIconClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            fill="#ffffff"
          >
            <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-75 29-147t81-128.5q52-56.5 125-91T475-881q21 0 43 2t45 7q-9 45 6 85t45 66.5q30 26.5 71.5 36.5t85.5-5q-26 59 7.5 113t99.5 56q1 11 1.5 20.5t.5 20.5q0 82-31.5 154.5t-85.5 127q-54 54.5-127 86T480-80Zm-60-480q25 0 42.5-17.5T480-620q0-25-17.5-42.5T420-680q-25 0-42.5 17.5T360-620q0 25 17.5 42.5T420-560Zm-80 200q25 0 42.5-17.5T400-420q0-25-17.5-42.5T340-480q-25 0-42.5 17.5T280-420q0 25 17.5 42.5T340-360Zm260 40q17 0 28.5-11.5T640-360q0-17-11.5-28.5T600-400q-17 0-28.5 11.5T560-360q0 17 11.5 28.5T600-320ZM480-160q122 0 216.5-84T800-458q-50-22-78.5-60T683-603q-77-11-132-66t-68-132q-80-2-140.5 29t-101 79.5Q201-644 180.5-587T160-480q0 133 93.5 226.5T480-160Zm0-324Z" />
          </svg>
        </button>
      )}
    </>
  );
}

export default Cookies;
