import React from "react";
import './Cybersecurity.css';
import imgPc from '../../../Assets/Img/Merendels-Sfondo-pc.jpg'

function Cybersecurity() {

    return (
        <div>

            <div className="hero-section-security">
                <img src={imgPc} alt="Cybersecurity" className="hero-image-security" />
                <h1 className="hero-text-security">CYBERSECURITY PMI</h1>
            </div>

            <div className="container-security01">
                <h2>Cosa è la Cyber Security?</h2>
                <p>Il termine Cyber Security, indica tutte le discipline che intendono focalizzare la propria attenzione principalmente sulla <strong>sicurezza dei sistemi informatici</strong> (computer, reti di tele-comunicazione, smartphone, ecc) <strong>proteggendoli da possibili attacchi o accessi non autorizzati,</strong> evitando così di corrompere e provocare danni alla reputazione di persone o aziende.</p>
                <p>Numerosi sono i sistemi che i Cyber criminali utilizzano per rubare informazioni ma possiamo raggrupparli in 3 gruppi:</p>
                <ol>
                    <li>Gli attacchi <strong>“comuni”,</strong> sfruttano campagne di phishing e diffusione di malware, i quali possono mettere a serio rischio la reputazione di un azienda.</li>
                    <li>Gli attacchi <strong>“avanzati”,</strong> sono invece, particolarmente sofisticati, i quali fanno leva sull’inefficacia o inadeguatezza dei sistemi di sicurezza per aprirsi un varco nei sistemi IT aziendali.</li>
                    <li>Infine ci sono gli attacchi <strong>0Day</strong> che sono quelli che danno maggiore preoccupazione in quanto fanno affidamento su strumenti, tecnologie  e codici mai messi in circolazione nel passato, questo tipo di attacco è il più difficile da individuare dato che nella maggior parte dei casi passa inosservato anche ai sistemi di sicurezza.</li>
                </ol>
                <hr />
            </div>

            <div className="container-security02">
                <h2>Quali rischi corrono le PMI?</h2>
                <p>In Italia le <strong>PMI</strong> costituiscono una realtà numericamente molto significativa: su 4.338.766 imprese, 4.335.448(il99,9%) sono, infatti, piccole e medie imprese. Inoltre, la quasi totalità di PMI ( il 95%) è costituita da imprese con meno di 10 addetti. Il resto è formato da imprese che impiegano da 10 a 49 addetti ( 196.090 unità, pari al 4,5%), mentre le imprese di taglia più grande ( da 50 a 249 addetti) sono appena 21.867, ossia lo 0,5% del totale. (Fonte ISTAT)</p>
                <p>Attualmente le <strong>medie e piccole imprese, in termini di IT Governance</strong> e gestione della sicurezza informatica, <strong>presentano le stesse necessità delle grandi imprese,</strong> a differenza di queste ultime però ,le piccole imprese non possono permettersi un presidio IT fisso in azienda,  che gestisca in maniera assidua e con competenza tutte le problematiche IT che si presentano.</p>
            </div>

            <div className="container-security03">
                <hr />
                <h2>Cosa Offre Merendels ?</h2>
                <p>Merendels ha sviluppato soluzioni per la gestione della CyberSecurity e la IT Governance delle PMI per tenere i propri dati sempre al sicuro.</p>
                <h2>LA NOSTRA OFFERTA</h2>
                <h5><strong>IT Governance</strong></h5>
                <p>Chiamato anche, ” governo dell’ IT “, intendiamo tutta quella parte che si occupa della gestione dei rischi informatici in un impresa e della risoluzione di problemi a loro legati.</p>
                <ol>
                    <li>Innovazione di prodotto</li>
                    <li>Introduzione di soluzioni informatiche innovative relative al dominio di business del cliente</li>
                    <li>Organizzazione dei sistemi informativi</li>
                    <li>Gestione dei fornitori e valutazione del processo di acquisto delle apparecchiature informatiche e dei programmi informatici</li>
                </ol>
                <h5><strong>Il Secondo passaggio sarà valutare i modelli di maturità e i processi di business</strong></h5>
                <ol>
                    <li>IT risk Management</li>
                    <li>Disaster Recovery</li>
                    <li>Business Continuity Plan</li>
                </ol>
                <h5><strong>Esercizio manutenzione e supporto infrastrutture informatiche</strong></h5>
                <h5><strong>KPI</strong></h5>
                <ol>
                    <li>Project Management</li>
                </ol>
                <h5><strong>Audit</strong></h5>
                <ol>
                    <li>Audit dei sistemi informativi</li>
                </ol>
                <h5><strong>Security</strong></h5>
                <ol>
                    <li>Gestione della sicurezza: classifica delle informazioni, definizione del piano della sicurezza, implementazione del piano, follow up secondo le direttive NIST</li>
                    <li>Vulnerability assessment</li>
                    <li>Penetration test</li>
                    <li>Black</li>
                    <li>White</li>
                    <li>Cyber Security e protezione delle informazioni</li>
                    <li>Gestione della Privacy</li>
                    <li>PCI DSS assessment per la certificazione dei processi di pagamenti elettronici</li>
                </ol>
            </div>

            <div className="container-security04">
                <h2>Contattaci subito per rinforzare la sicurezza della tua azienda</h2>
                <a href="mailto:info@merendels.com"><button>Parlaci della tua azienda</button></a>
            </div>

        </div>
    )
}

export default Cybersecurity