import React from "react";
import './InternetOfThings.css';
import imgPc from '../../../Assets/Img/Merendels-Sfondo-pc.jpg'
import industria from '../../../Assets/Img/ind.png'
import casaDomotica from '../../../Assets/Img/Merendels-domotica.png'
import healthcare from '../../../Assets/Img/Merendels-Healthcare.png'
import retail from '../../../Assets/Img/merendels-retail.png'

function InternetOfThings() {

    return (
        <div>

            <div className="hero-section-iot">
                <img src={imgPc} alt="InternetOfThings" className="hero-image-iot" />
                <h1 className="hero-text-iot">INTERNET OF THINGS</h1>
            </div>

            <div className="container-p-iot">
                <h2>Creare soluzioni Iot Intelligenti</h2>
                <p>Prodotti e soluzioni originali nell’ ambito della progettazione hardware e software di dispositivi Iot, della memorizzazione, la gestione e dell’analisi dei dati prodotti, gestendone tutto il ciclo di vita: dalla prototipazione alla produzione in volumi.</p>
                <p>Oggi persone e aziende sentono sempre di più l’esigenza di fare affidamento su strumenti ed oggetti in grado di migliorare il loro tenore di vita, dandogli così un servizio confortevole e allo stesso tempo tecnologico.</p>
                <p>La maggior parte di queste tecnologie, ormai tanto ricercate, può essere adattata a qualsiasi  oggetto, sia che si tratti di automobili, sensori della temperatura o un semplice carrello della spesa.</p>
                <p><strong>L’ Iot rende gli oggetti intelligenti , migliorando il nostro modo di vivere e di lavorare.</strong></p>
                <p>Nella sua forma più semplice l’ Iot è costituito da una rete di oggetti connessi tra loro grazie ad una connessione wireless, in grado di elaborare dei dati, da cui poi estraggono informazioni utili a chi li usa.</p>
                <p>Le aziende che vogliono utilizzare questa nuova tecnologia, sono veramente molte, dato che può portare vantaggi decisamente concreti ma è anche vero che per utilizzarla nel migliore dei modi, dovranno tenere in considerazione diversi aspetti.</p>
                <p>Per prima cosa si dovranno connettere gli oggetti al cloud, gestire ed elaborare i dati generati, tutto questo in condizioni di massima sicurezza.</p>
                <hr />
            </div>

            <div className="container-tot-card-iot">
                <h2>Merendels può offrire la soluzione.</h2>
                <p>Ecco alcuni esempi in cui l’ Iot può essere un’opportunità di business e come Merendels è in grado di realizzarle.</p>
                <div className="container-card-iot">
                    <div className="card-iot" style={{ backgroundColor: 'rgb(93, 157, 209)' }}>
                        <h2>Industria</h2>
                        <img src={industria} alt="" />
                        <p> La possibilità di analizzare i dati da ogni collegamento della catena di valore della produzione consente alle aziende di aumentare l’efficienza, ottimizzare la produzione e ridurre i costi.</p>
                    </div>
                    <div className="card-iot">
                        <h2>Casa domotica</h2>
                        <img src={casaDomotica} alt="" />
                        <p>  Utilizziamo oggi il termine casa intelligente o smart home per indicare un ambiente tecnologicamente avanzato, che tramite la gestione di dispositivi domotici interconnessi, è in grado di offrire benefici e facilitazioni all’ utente. </p>
                    </div>
                    <div className="card-iot">
                        <h2>Healthcare</h2>
                        <img src={healthcare} alt="" />
                        <p>Healthcare è un modo per rendere gli ospedali e i suoi strumenti più efficienti, fornire ai medici i dati importanti dei pazienti grazie a sistemi intelligenti, accelerando così, i sistemi di guarigione.</p>
                    </div>
                    <div className="card-iot" style={{ backgroundColor: 'rgb(93, 157, 209)' }}>
                        <h2>Retail</h2>
                        <img src={retail} alt="" />
                        <p>L’IoT ha consentito alle aziende di comprendere le esigenze e le preferenze del cliente, tanto da interagire con il consumatore in modo proattivo e utilizzando tutti i dati raccolti attraverso software basati sul cloud.</p>
                    </div>
                </div>
                <div className="container-btn-contattaci-iot">
                    <h2>Vuoi condividere con noi il tuo prossimo progetto?</h2>
                    <a href="mailto:info@merendels.com"><button>Contattaci</button></a>
                </div>
            </div>

        </div>
    )
}

export default InternetOfThings