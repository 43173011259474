import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import logo from '../../Assets/Img/LOGO_MERENDELS.png';


function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <div className='container-header'>
            <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
                <Link to="/"><img id="logo" src={logo} alt="logo" /></Link>
                <nav className="nav">
                    <p className="nav-item">
                        <Link to="/">HOME</Link>
                    </p>
                </nav>
            </header>
        </div>
    );
}


export default Navbar