import React from "react";
import './Blockchain.css';
import imgPc from '../../../Assets/Img/Merendels-Sfondo-pc.jpg'

function Blockchain() {

    return (
        <div>

            <div className="hero-section-blockchain">
                <img src={imgPc} alt="Blockchain" className="hero-image-blockchain" />
                <h1 className="hero-text-blockchain">Blockchain</h1>
            </div>

            <div className="container-blockchain01">
                <h2>Servizio presto disponibile</h2>
            </div>

        </div>
    )
}

export default Blockchain