import React from "react";
import PrivacyPolicy01 from '../../Assets/Img/privacy policy01.jpg';
import PrivacyPolicy02 from '../../Assets/Img/privacy policy02.jpg';


function PrivacyPolicy() {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={PrivacyPolicy01} alt="Privacy Policy 01" style={{ width: "80%", marginBottom: "20px" }} />
      <img src={PrivacyPolicy02} alt="Privacy Policy 02" style={{ width: "80%" }} />
    </div>
  );
}

export default PrivacyPolicy;
