import React from "react";
import './SviluppoSoftware.css';
import imgPc from '../../../Assets/Img/Merendels-Sfondo-pc.jpg'

function SviluppoSoftware() {

    return (
        <div>

            <div className="hero-section-software">
                <img src={imgPc} alt="SviluppoSoftware" className="hero-image-software" />
                <h1 className="hero-text-software">Sviluppo Software</h1>
            </div>

            <div className="container-software01">
                <h2>Merendels e i Software</h2>
                <p><strong>La nostra passione per questa disciplina nasce più di 30 anni fa…</strong></p>
                <p>Abbiamo visto nascere e crescere l’ informatica in ogni sua sfaccettatura, iniziando ad utilizzare i complicatissimi <strong>Ms-Dos fino alle più moderne tecnologie odierne.</strong></p>
                <p><strong>Siamo sempre stati mossi dal desiderio di creazione</strong> e per questo da più di 30 anni studiamo e approfondiamo sempre di più i vari linguaggi di programmazione un po’ per dare al cliente la migliore esperienza possibile ma soprattutto perché ci piace quello che facciamo!</p>
                <p>Abbiamo iniziato a studiare il <strong>linguaggio C,</strong> per capire la logica di funzionamento dei Videogame quando avevamo solo 12 anni.</p>
                <p>La passione ci ha portato poi ad approfondire sempre di più i vari linguaggi tra cui:<strong>C, C++, C#, Java, Visual Basic , Java Script, Php, Symfony, Python.</strong></p>
            </div>

            <div className="container-software02">
                <hr />
                <h2>Come possiamo aiutarti</h2>
                <p>Una delle nostre specializzazioni è realizzare applicazioni complesse sia web che mobile ma anche progettare software di vario genere adattando in base alle caratteristiche richieste il linguaggio utilizzato e l’architettura del progetto.</p>
                <p>Il nostro obbiettivo è quello di fornire un servizio  di alta qualità , partendo da un analisi dei dati e dalle necessità del cliente.</p>
                <p>Procederemo insieme alla creazione di una <strong>Rod-Map</strong> fatta su misura ,dove analizzeremo ogni dettaglio, e fornendo soluzioni ad hoc, <strong>costruiremo passo dopo passo un software di alto valore per il cliente.</strong></p>
                <p>Il nostro approccio è la migliore garanzia che possiamo offrire per ridurre al minimo crash, bug o malfunzionamenti, rilasciando un software funzionante in tempi abbastanza brevi.</p>
            </div>

            <div className="container-software03">
                <hr />
                <h2>Servizi di sviluppo software:</h2>
                <ul>
                    <li>Applicazioni Web</li>
                    <li>Applicazioni Mobile</li>
                    <li>Piattaforme Web</li>
                    <li>Software as Service</li>
                    <li>Plug-in e Estensioni</li>
                    <li>Web Utility & Tool</li>
                </ul>
            </div>

            <div className="container-software04">
                <h2>Contattaci subito e presentaci il tuo progetto di Business</h2>
                <a href="mailto:info@merendels.com"><button>Parlaci del tuo progetto</button></a>
            </div>

        </div>
    )
}

export default SviluppoSoftware