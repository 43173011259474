import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from './componenti/ScrollToTop';
import './App.css';
import Home from './componenti/Home/Home';
import Navbar from './componenti/Navbar/Navbar';
import Footer from './componenti/Footer/Footer';
import Cookies from './componenti/Cookies/Cookies';
import PrivacyPolicy from './componenti/InformativaOnline/PrivacyPolicy';
import InternetOfThings from './componenti/ScopriDiPiù/InternetOfThings/InternetOfThings';
import SviluppoSoftware from './componenti/ScopriDiPiù/SviluppoSoftware/SviluppoSoftware';
import Cybersecurity from './componenti/ScopriDiPiù/Cybersecurity/Cybersecurity';
import Blockchain from './componenti/ScopriDiPiù/Blockchain/Blockchain';


function App() {

  const [showPolicy, setShowPolicy] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);

  // Mostra la schermata di personalizzazione
  const handleCustomize = () => {
    setShowCustomize(true);
    setShowPolicy(false);
  };

  // Funzione per aprire il modal della Cookie Policy
  const handlePolicyClick = () => {
    setShowPolicy(true);
  };

  // Funzione per chiudere il modal della Cookie Policy
  const handleClosePolicy = () => {
    setShowPolicy(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Internet-Of-Things" element={<InternetOfThings />} />
          <Route path="/Cyber-Security" element={<Cybersecurity />} />
          <Route path="/Sviluppo-Software" element={<SviluppoSoftware />} />
          <Route path="/Blockchain" element={<Blockchain />} />
        </Routes>
        <Cookies
          showPolicy={showPolicy}
          handleClosePolicy={handleClosePolicy}
          handlePolicyClick={handlePolicyClick}
          showCustomize={showCustomize}
          handleCustomize={handleCustomize}
          setShowCustomize={setShowCustomize} />
        <Footer
          onCookiePolicyClick={handlePolicyClick}
          onCookieSettings={handleCustomize} />
      </div>
    </Router>
  );
}

export default App;
